/* prettier-ignore */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

code {
    font-family: "Poppins", sans-serif;
}


::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: gray;
    height: 6px;
}

@media (max-width: 800px) {
    ::-webkit-scrollbar {
        display: none;
    }

    ::-webkit-scrollbar-thumb {
        display: none;
    }
}

.dropdownBackground {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.lkjDVC {
    border: none !important;
}

.toggle-switch {
    position: relative;
    width: 45px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}

.toggle-switch-checkbox {
    display: none;
}

.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
}

.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner:before,
.toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 25px;
    padding: 0;
    line-height: 25px;
    font-size: 14px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
}

.toggle-switch-inner:before {
    font-family: FontAwesome;
    content: "\f00c";
    text-transform: uppercase;
    padding-left: 7px;
    background-color: #4f46e5;
    color: #fff;
}

.toggle-switch-disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

.toggle-switch-disabled:before {
    background-color: #ddd;
    cursor: not-allowed;
}

.toggle-switch-inner:after {
    font-family: FontAwesome;
    content: "\f00d";
    text-transform: uppercase;
    padding-right: 10px;
    background-color: rgb(238, 234, 234);
    color: #6e6b7b;
    text-align: right;
}

.toggle-switch-switch {
    display: block;
    width: 15px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 45%;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
    box-shadow: 0px 0px 10px gray;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}

.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
    right: 0px;
}

#chooseColor {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#chooseColor::-webkit-color-swatch {
    border-radius: 3px;
    border: none;
}

#chooseColor::-moz-color-swatch {
    border: none;
}

/* live */

.dtlKip {
    border: none !important;
}

.sc-aXZVg {
    min-height: 300px;
    border: none !important;
    display: flex;
    justify-items: center;
    align-items: center;
    z-index: 0;
}

.sc-aXZVg svg {
    display: none;
}

.sc-aXZVg .sc-eqUAAy span span {
    display: none;
}

.sc-aXZVg .sc-eqUAAy {
    margin: auto;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    background-color: #4f46e5;
    display: flex;
    justify-content: center;
    padding: 7px 0;
    border-radius: 20px;
}

.sc-aXZVg .sc-eqUAAy span {
    color: white;
    text-align: center;
    font-size: 18px;
    z-index: 0;
}

/* live */


.sc-bcXHqe {
    min-height: 300px;
    border: none !important;
    display: flex;
    justify-items: center;
    align-items: center;
    z-index: 0;
}

.sc-bcXHqe svg {
    display: none;
}

.sc-bcXHqe .sc-dkrFOg span span {
    display: none;
}

.sc-bcXHqe .sc-dkrFOg {
    margin: auto;
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    background-color: #4f46e5;
    display: flex;
    justify-content: center;
    padding: 7px 0;
    border-radius: 20px;
}

.sc-bcXHqe .sc-dkrFOg span {
    color: white;
    text-align: center;
    font-size: 18px;
    z-index: 0;
}

.iBDCDI {
    min-width: 500px;
    z-index: 999;
    background-color: #4f46e5;
}

.iBDCDI span {
    color: white;
    min-width: 500px;
    text-align: center;
}

.ReactCrop__child-wrapper {
    height: 100%;
}

.cropper-bg {
    z-index: 999;
    background-image: url("https://t4.ftcdn.net/jpg/01/16/67/99/360_F_116679941_wPDZXXs58H5SKL15YDeC2xoRaenOjGvV.jpg");
}

._1PrPg > div {
    padding: 20px;
}

.contact .css-qbdosj-Input {
    padding: 6px;
}

.contact .css-1dimb5e-singleValue {
    color: gray;
}

.pre .css-1dimb5e-singleValue {
    color: black;
}

#react-select-3-listbox div {
    z-index: 9999;

    opacity: 1 !important;
}

.otpInput {
    outline: none;
    min-width: 35px;
    min-height: 40px;
    border-radius: 5px;
    margin: 0 7px;
    font-size: 25px;
    color: white;
    background: #4f46e5;
}

.css-1jqq78o-placeholder {
    color: black;
}

.css-tj5bde-Svg {
    color: black;
}

.inputStyle ::-webkit-input-placeholder {
    color: #4f46e5;
}

.pagination ul {
    display: flex;
    align-items: center;
}

.pagination > li {
    display: inline-block;
    padding-left: 0;
}

.pagination > li {
    list-style: none;
    margin: 0 5px;
}

.pagination > li > a,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 6px 13px;
    line-height: 1.1;
    text-decoration: none;
    color: #4f46e5;
    /* background-color: #fff; */
    margin-left: -1px;
}

.pagination > li.active > a {
    color: #fff;
    background-color: #4f46e5;
    border-radius: 5px;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
    background-color: #4f46e5;
    color: white;
    border-radius: 5px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
    border-bottom-right-radius: 4px;
    margin-right: 0;
    padding: 0px 7px;
    font-size: 25px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-bottom-right-radius: 4px;
    margin-right: 0;
    padding: 0px 7px;
    font-size: 25px;
}

.react-datepicker-popper {
    left: 165px;
    top: -30px;
}

.PhoneInputInput {
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.qr canvas {
    margin-top: 20px;
    width: 70%;
    height: auto;
}

.back {
    text-decoration: none;
    color: #4f46e5;
}

.back:hover {
    text-decoration: underline;
}

.customInputSize input {
    padding-left: 62px !important;
    margin: 0 !important;
    width: 100% !important;
    /* height: 57px !important; */
    padding-block: 1.4rem !important;
    /* padding-inline: 1rem !important; */
    border: none !important;
    border-radius: 12px !important;
}

.customInputSize input:focus {
    outline: none !important;
}

.customInputSize .flag-dropdown .selected-flag:hover,
.customInputSize .flag-dropdown .selected-flag.open:hover,
.customInputSize .flag-dropdown .selected-flag.open,
.customInputSize .flag-dropdown.open {
    background: #f5f5f5 !important;
}

.customInputSize .flag-dropdown {
    border: none !important;
    padding-inline: 8px !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.brand {
    @apply my-3 w-full lg:w-[80%] lg:mx-auto grid grid-flow-row lg:grid-flow-col xs:place-content-center ms:place-content-center sm:place-content-center md:place-content-center items-center gap-4 lg:grid-cols-3;
}

.inpuStyle {
    @apply flex items-center justify-between rounded-lg border-2 border-blue h-[40px] overflow-hidden;
}

.inputStyle_input {
    @apply w-full px-[1px] outline-none border-none;
}

.inputStyle_right {
    @apply bg-blue flex items-center justify-center w-12 h-full;
}

.inpuStyle_right_icon {
    @apply cursor-pointer text-white hover:text-gray  transition-all duration-300;
}

.ribbon {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 150px;
    min-height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.ribbon::before {
    content: "Most Popular";
    position: absolute;
    width: 150%;
    height: 40px;
    background: #fedc01;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    rotate: 45deg;
    transform: translateY(-20px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.ribbon::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 10px;
    height: 10px;
    background: rgb(113, 141, 150);
    z-index: -1;
    box-shadow: -140px -140px rgb(113, 141, 150);
}

.customInputSize input {
    padding-left: 62px;
    margin: 0;
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 10px;
}

.contact_no input {
    padding-left: 62px !important;
    margin: 0 !important;
    width: 100% !important;
    height: 45px !important;
    border: none !important;
}

.contact_no input:focus {
    outline: none !important;
}

.contact_no .flag-dropdown .selected-flag:hover,
.contact_no .flag-dropdown .selected-flag.open:hover,
.contact_no .flag-dropdown .selected-flag.open,
.contact_no .flag-dropdown.open {
    background: #f5f5f5 !important;
}

.contact_no .flag-dropdown {
    border: none !important;
    padding-inline: 8px !important;
}

.reportTitle {
    @apply text-2xl font-semibold mt-3;
}

.report_subcontent {
    @apply flex items-center justify-end gap-x-4 flex-col l1:flex-row l2:flex-row  l3:flex-row  t1:flex-row   t2:flex-row   t3:flex-row   t4:flex-row   t5:flex-row;
}

.report_input {
    @apply m1:w-[200px] m2:w-[100%] m3:w-[100%] m4:w-[100%] m5:w-[100%] m6:w-[100%] m7:w-[100%] m8:w-[100%] t1:w-[80%] t2:w-[80%] t3:w-[80%] t4:w-[80%] t5:w-[80%] l1:w-[300px]  l2:w-[300px] l3:w-[300px] my-2 overflow-hidden border-2 border-grayLight bg-white rounded-md py-2 outline-none px-1 flex items-center;
}

.searchbox_icon {
    @apply mx-1 text-gray;
}

.searchbox {
    @apply flex-1 bg-white outline-none px-2;
}

.report_dropdown {
    @apply m1:w-[200px] m2:w-[100%] m3:w-[100%] m4:w-[100%] m5:w-[100%] m6:w-[100%] m7:w-[100%] m8:w-[100%] l1:w-[150px] l2:w-[150px] l3:w-[150px] t1:w-[150px]  t2:w-[150px]  t3:w-[150px]  t4:w-[150px]  t5:w-[150px] 
  border-2 border-grayLight bg-white rounded-md py-2 outline-none px-1 flex items-center cursor-pointer;
}

.report_selected_element {
    @apply flex items-center justify-between px-3;
}

.dropdown_content {
    @apply w-full absolute top-12 border-2 border-grayLight bg-white rounded-md p-2;
}

.dropdown_content_text {
    @apply py-2 px-3 rounded blueHover;
}

.report_table {
    @apply m1:w-[200px] m2:w-[100%] m3:w-[100%] m4:w-[100%] m5:w-[100%] m6:w-[100%] m7:w-[100%] m8:w-[100%]  overflow-auto;
}

.report_table_head {
    @apply w-[100%]  bg-transparent rounded-md border-b-2 border-grayLight overflow-auto;
}

.table_head_title {
    @apply m2:w-[100px]  py-3.5 px-4 text-center text-[12px] font-[600] text-[#71717A] uppercase;
}

.reports_table_head_content {
    @apply w-[400px];
}

.no-event {
    @apply min-h-[calc(100vh-140px)] flex items-center justify-center flex-col bg-white;
}

.no-event img {
    @apply m1:w-[300px] m1:h-[300px] m2:w-[300px] m2:h-[300px] m3:w-[300px] m3:h-[300px] m4:w-[300px] m4:h-[300px] m5:w-[300px] m5:h-[300px] m6:w-[300px] m6:h-[300px] m7:w-[300px] m7:h-[300px] m8:w-[300px] m8:h-[300px];
}

.no-event-content {
    @apply text-center m1:text-xs m2:text-base m3:text-xl m4:text-xl m5:text-xl m6:text-xl m7:text-xl m8:text-xl 
  t1:text-2xl t2:text-2xl t3:text-2xl t4:text-2xl t5:text-2xl 
  font-bold mt-6 text-black;
}

.react_table_body_content {
    @apply text-center px-4 py-4 text-sm font-medium text-grayDark lg:table-cell whitespace-nowrap;
}

.react_table_body_content_1 {
    @apply absolute translate-x-12  px-4 py-4 text-sm font-medium text-grayDark lg:table-cell whitespace-nowrap;
}

.react_table_body_content_text {
    @apply flex items-center justify-center  font-inter;
}

.react_table_body_content_text1 {
    @apply flex items-center justify-center  font-inter text-grayDark hover:text-gray;
}

.react_table_btn {
    @apply bg-blue px-4 py-1 text-white rounded-md text-base hover:bg-transparent hover:text-blue transition-all duration-200 border-2 border-blue;
}

.global_main {
    @apply flex min-h-[calc(100vh-140px)];
}

/* .global_sidemenu {
  @apply w-[350px]  t1:w-[250px] t2:w-[250px] t3:w-[250px] t4:w-[250px] t5:w-[250px]  l1:w-[350px] l2:w-[350px] l3:w-[350px] bg-white pt-6 px-6 t1:px-3 t2:px-3 t3:px-3 t4:px-3 t5:px-3 l1:px-6 l2:px-6 l3:px-6;
} */
.global_sidemenu {
    @apply bg-white px-4 l2:px-6 sticky gap-4 flex flex-col top-[60px] overflow-hidden z-10 h-[calc(100vh-60px)] pt-4 transition-all duration-300;
}

.global_template {
    @apply w-full p-6 l2:p-10 max-w-7xl mx-auto l3:w-screen;
}

/* .global_template {
    container: globalTemplate / inline-size;
}
@container globalTemplate (max-width: 800px) {
    .qr_Code {
        flex-direction: column-reverse !important;
    }
} */

.global_sidemenu_item {
    @apply py-4 flex items-center relative gap-x-4 cursor-pointer rounded-lg px-4 transition-all duration-300 overflow-hidden;
}
.global_sidemenu_item_text {
    @apply line-clamp-1;
}

/* .global_sidemenu_item_icon {
  @apply absolute left-6 t1:left-2  t2:left-2 t3:left-2 t4:left-2 t5:left-2 l1:left-6 l2:left-6 l3:left-6;
} */

.qr_Code {
    @apply flex-1 flex gap-6 l2:gap-10 max-l2:flex-col-reverse l2:flex-row max-w-7xl mx-auto;
}

.template1 {
    @apply flex-1 bg-[#FAFAFA];
}

.template2 {
    @apply flex-1 min-h-full flex  justify-center;
}

.email_template2 {
    @apply flex-1 min-h-full card;
}

/* globalBranding */

.brand_main {
    @apply mt-2;
}

.brand_box {
    @apply mt-5 px-10 w-[600px];
}

.brand_title {
    @apply text-2xl font-bold;
}

.brand_subTitle {
    @apply mt-4 text-lg text-gray;
}

.brand_upload {
    @apply flex items-center gap-x-10;
}

.brand_upload_img {
    @apply border-2 border-gray w-[200px] h-[100px] overflow-hidden p-1 my-2 rounded-md flex items-center justify-center;
}

.brand_upload_img img {
    @apply w-[80%] h-[80%] object-contain;
}

.brand_upload_btn {
    @apply flex items-center gap-x-6;
}

.brand_upload_text {
    @apply text-blue text-base font-medium cursor-pointer;
}

.brand_upload_remove_text {
    @apply text-red text-base font-medium cursor-pointer;
}

.brand_company_logo {
    @apply mt-8;
}

.brand_company_logo_title {
    @apply font-semibold text-base my-3;
}

.brand_welcome_bg {
    @apply mt-8;
}

.brand_welcome_bg_title {
    @apply font-semibold text-base my-3;
}

.brand_line {
    @apply text-grayLight mt-6;
}
.brand_colors_container {
    @apply flex items-center justify-center;
}

.brand_colors {
    @apply flex items-center justify-center gap-x-6;
}

.brand_color_content {
    @apply flex-col w-[120px] h-[120px] flex items-center;
}

.brand_input_tag_head {
    @apply my-3 flex items-center justify-center;
}

.brand_input_tag_head p {
    @apply text-sm font-semibold text-center;
}

.brand_input_color {
    @apply min-w-[70px] min-h-[70px] flex cursor-pointer border-0 outline-0 bg-white rounded-full overflow-hidden;
}

#globalchooseColor {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

#globalchooseColor::-webkit-color-swatch {
    border: none;
    border-radius: 50%;
}

#globalchooseColor::-moz-color-swatch {
    border: none;
}

.brand_update_btn {
    @apply bg-blue w-[90%] py-2 rounded-md text-white;
}

/* qr template */

.main {
    @apply w-full h-full pt-10 px-10;
}

.head {
    @apply text-2xl font-bold font-inter py-4;
}

.sub_title {
    @apply py-2 text-grayDark font-inter;
}

.input_tag {
    @apply flex flex-col my-6;
}

.input_tag_head {
    @apply w-full flex items-center justify-between py-1;
}
.input_tag_head p {
    @apply text-base font-semibold;
}

.input_tag_input {
    @apply w-full border-2 border-gray my-3 py-1 rounded-sm flex items-center;
}

.input {
    @apply w-full py-2 outline-none border-none px-3;
}

.react-pdf__Page {
    height: 400px;
}

.markedContent {
    display: none;
}

.template {
    @apply w-full h-[800px] !bg-white rounded-sm overflow-hidden;
}

.template_content1 {
    @apply w-full  flex items-center justify-center;
}

.template_content1_text {
    @apply w-full py-3  text-base  text-center;
}

/* .template_content_logo {
  @apply w-[220px] h-[120px] overflow-hidden   flex items-center justify-center;
} */
.template_content_logo {
    @apply h-[120px] max-w-[200px] aspect-auto overflow-hidden   flex items-center justify-center;
}

.template_content_logo img {
    @apply w-[100%] max-h-[100%] object-contain;
}

.template_content_title {
    @apply w-[80%] break-words font-bold text-3xl text-center absolute top-[28%];
}

.QR_code {
    @apply border-4 border-black w-[200px] h-[200px] flex items-center justify-center rounded-lg absolute top-[40%];
}

.QR_code img {
    @apply w-[90%] h-[180px];
}

.btn {
    @apply w-[150px] text-center  py-2 rounded-md border-2  cursor-pointer font-semibold 
  text-lg absolute bottom-[27%];
}

.template_content_description {
    @apply w-[80%] break-words text-lg font-semibold text-center absolute top-[76%];
}

.template_content_brand {
    @apply w-full flex items-center px-3 py-4;
}

.template_content_brand_left {
    @apply w-[60%] break-words  font-semibold text-sm pr-10;
}

.template_content_brand_right {
    @apply w-[40%] break-words font-semibold text-sm;
}

.template_content_brand_content {
    @apply w-full  flex items-center justify-center absolute bottom-0;
}

.template_download_btn {
    @apply flex items-center gap-x-6;
}

.template_download_btn_content {
    @apply w-[200px] cursor-pointer flex items-center justify-center text-blue border-2 border-blue py-2 rounded-md text-base hover:bg-blue hover:text-white transition-all duration-200;
}

.template_download_btn_content p {
    @apply font-semibold ml-3 mt-1;
}

.template_download_btn_content1 {
    @apply w-[200px] cursor-pointer flex items-center justify-center bg-blue text-white py-2 rounded-md text-base hover:bg-white hover:text-blue transition-all duration-200 border-2 border-blue;
}

.template_download_btn_content1 p {
    @apply font-semibold ml-3 mt-1;
}

.qr_logo_upload {
    @apply flex items-center gap-x-4;
}

.qr_logo_img {
    @apply border-2 border-gray w-[200px] h-[100px] overflow-hidden p-1 my-2 rounded-md flex items-center justify-center;
}

.qr_logo_img img {
    @apply w-[80%] h-[80%] object-contain;
}

.qr_logo_btn {
    @apply flex items-center gap-x-6;
}

.qr_logo_text {
    @apply text-blue text-base font-medium cursor-pointer;
}

.qr_logo_remove {
    @apply text-red text-base font-medium cursor-pointer;
}

.template_content_link {
    @apply w-full  flex items-center justify-center absolute top-0;
}

.drop_down {
    @apply w-full border border-[#D4D4D8] my-3 rounded-md flex items-center;
}

.drop_down_select {
    @apply flex items-center py-3 px-3 cursor-pointer;
}

.drop_down_list {
    @apply w-full max-h-[300px] border border-gray my-3 absolute top-10 bg-white p-2 z-[999] shadow-lg rounded-md overflow-auto dropdownBackground;
}

.drop_down_list_text {
    @apply py-2 px-3  w-full blueHover cursor-pointer font-inter rounded;
}

.color_content {
    @apply flex items-center gap-x-4;
}

.color_content_text {
    @apply font-semibold text-base font-inter cursor-pointer;
}

.color {
    @apply flex items-center gap-14;
}

/* Email template */
.email_main {
    @apply w-full !h-fit  p-10 card;
}

.email_head {
    @apply text-2xl font-bold font-inter;
}

.email_sub_title {
    @apply mt-3 text-grayDark font-inter;
}

.email_input_tag {
    @apply flex flex-col my-6;
}

.email_input_tag_head {
    @apply w-full flex items-center justify-between;
}
.email_input_tag_head p {
    @apply text-base font-semibold;
}

.email_input_tag_input {
    @apply mt-3 !outline-none border block w-full px-4 py-3 placeholder-[#52525B] border-[#D4D4D8] rounded-lg focus:border-[#4F46E5] sm:text-sm caret-[#4F46E5];
}

.email_input {
    @apply w-full  outline-none border-none;
}

.email_color {
    @apply flex items-center gap-14;
}

.email_color_content {
    @apply flex items-center cursor-pointer gap-x-4;
}

.email_color_content_text {
    @apply font-semibold text-base font-inter cursor-pointer;
}

.email_input_color {
    @apply min-w-[70px] min-h-[70px] flex cursor-pointer border-0 outline-0 bg-transparent rounded-full overflow-hidden;
}

.email_qr_logo_upload {
    @apply flex items-center gap-x-4;
}

.email_qr_logo_img {
    @apply border-2 border-gray w-[200px] h-[100px] overflow-hidden p-1 my-2 rounded-md flex items-center justify-center;
}

.email_qr_logo_img img {
    @apply w-[80%] h-[80%] object-contain;
}

.email_qr_logo_btn {
    @apply flex items-center gap-x-6;
}

.email_qr_logo_text {
    @apply text-blue text-base font-medium cursor-pointer;
}

.email_qr_logo_remove {
    @apply text-red text-base font-medium cursor-pointer;
}
.email_brand_container {
    @apply flex items-center gap-x-16 mt-8;
}

.email_pre_container {
    @apply w-[100%]  min-h-[600px] flex flex-col mx-auto overflow-hidden rounded-md p-10;
}

.email_logo {
    @apply w-full h-[100px] overflow-hidden mb-2 rounded-md flex items-center justify-center;
}

.email_logo img {
    @apply w-[200px] h-[100px] object-contain;
}
.email_content {
    @apply w-[100%] h-[750px] rounded-md;
}

.email_pre_content {
    @apply pt-8;
}

.email_pre_content h1 {
    @apply text-2xl font-bold font-inter text-start;
}

.email_pre_content p {
    @apply text-lg font-inter;
}

.general_main {
    @apply mt-8 card;
}

.general_box {
    @apply py-4 px-10 min-h-[400px];
}

.general_tag {
    @apply flex flex-col my-6;
}

.general_tag_head {
    @apply w-full flex items-center justify-between py-1;
}

.general_title {
    @apply flex flex-col;
}

.general_title p {
    @apply text-base font-semibold;
}

.general_title span {
    @apply text-grayDark text-base font-light pt-1;
}

.loop_dropdown_container {
    @apply w-[50%] border-2 border-grayLight rounded-md my-1;
}

.loop_selected {
    @apply px-2  flex items-center min-h-[42px];
}

.loop_selectedItem {
    @apply flex flex-1 items-center flex-wrap;
}

.event_selected {
    @apply px-3 py-[2px] flex items-center gap-x-3 bg-[#4e46e52d] rounded-full ml-2 mr-1 my-1;
}

.loop_dropdownlist {
    @apply my-2 p-4 w-[100%] h-[300px] overflow-hidden bg-white border-2 border-grayLight rounded-md dropdownBackground;
}
.loop_list_item {
    @apply w-[100%] h-[80%] overflow-auto pb-10;
}

.loop_dropdownlist p {
    @apply py-2 px-4 blueHover cursor-pointer rounded-lg;
}

.loop_selectedItem_icons {
    @apply w-[40px] flex items-center;
}
.branding_img_container {
    @apply w-[80%] bg-white  border-2 border-grayLight rounded-xl p-8;
}

.branding_color_container {
    @apply w-[80%] bg-white  border-2 border-grayLight rounded py-4;
}

.inside_content {
    @apply p-2 font-semibold capitalize cursor-pointer px-4  w-full blueHover rounded;
}

.icon_alignment {
    @apply  flex items-center justify-center bg-[#4F46E5] py-1 min-w-[60px] rounded-md text-white font-inter mt-1 gap-x-1;
}

.button {
    @apply active:scale-95 text-sm l3:text-base disabled:cursor-not-allowed disabled:opacity-90 inline-flex items-center justify-center px-3 py-2 transition-all duration-200 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-60 cursor-pointer;
}

.primaryButton {
    @apply button text-white bg-[#4F46E6] focus:ring-lightBlue hover:bg-[#4e46e6c0];
}
.secondaryButton {
    @apply button text-[#4F46E6] bg-white outline outline-1 outline-blue focus:ring-lightBlue hover:text-white hover:bg-[#4F46E6];
}

.card {
    border: 1px solid #e2e8f0;
    background: #fff;
    border-radius: 0.75rem;
}

.days {
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    gap: 10px 10px;
}

.days div {
    border: 1px solid #d8d6de;
    border-radius: 38px;
    padding: 4px 16px;
    color: rgba(110, 107, 123, 0.75);
    cursor: pointer;
}

.days .active {
    background: rgba(0, 140, 229, 0.12) !important;
    color: #4F46E5 !important;
    border-color: #4F46E5 !important;
}

.sectionPadding {
    padding-inline: 16px !important;
}

.blueHover {
    @apply hover:!bg-bgLightBlue hover:!text-[#4F46E5];
}

/* custom switch design override */
/* .react-switch-bg {
    @apply flex;
} */

/* responsive */

@media (min-width: 480px) {
}

@media (min-width: 768px) {
    .sectionPadding {
        padding-inline: 32px !important;
    }
}

@media (min-width: 1024px) {
    .sectionPadding {
        padding-inline: 40px !important;
    }
}

@media (min-width: 1280px) {
    .sectionPadding {
        padding-inline: 64px !important;
    }
}

@media (min-width: 1440px) {
    .sectionPadding {
        padding-inline: 80px !important;
    }
}

.search {
    display: flex !important;
    align-items: center !important;
    padding: 0px 0 -1px 0px !important;
}

.search-box {
    border: 1px solid #cacaca;
    border-radius: 3px !important;
    font-size: 15px !important;
    line-height: 0px !important;
    margin-left: 6px !important;
    padding: 0px 0px 0px !important;
    outline: none !important;
}
