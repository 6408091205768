.question {
  margin: 15px 0;
  display: flex;
  justify-content: center;
}

.formCheck {
  background: #e6e6e6;
  margin: 5px 0;
  padding: 10px 0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.formCheck input {
  margin: 3px 0;
  padding: 15px 10px;
  cursor: pointer;
}

.formCheck label {
  margin-top: -2px;
  font-size: 16px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
}

.formCheck input[type="checkbox" i] {
  appearance: none;
  -webkit-appearance: none;
  height: 5px;
  width: 5px;
  background: whitesmoke;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formCheck input[type="checkbox" i]::after {
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 22px;
  color: #737373;
  display: none;
}

.formCheck input[type="checkbox" i]:checked {
  background: transparent;
}

.formCheck input[type="checkbox" i]:checked::after {
  display: block;
}

@media (max-width: 295px) {
  .formCheck {
    min-width: 230px;
    margin: 15px 20px;
  }
}
